import { DocumentIcon } from '@heroicons/react/24/outline'
import {
	ConfirmationDialog,
	Button,
	Dropdown,
	Input,
	DateSelect,
	Checkbox,
} from 'common'
import { JobsServices, SWMSDocumentsServices, StaffServices } from 'services'
import { ProgressSpinner } from 'primereact/progressspinner'
import moment from 'moment'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import {
	OptionsForDropdown,
	OptionsForDropdownFilter,
	OptionsForDropdownNumber,
} from 'utilities'
import { optionsDeckLevel, optionsTowerSize } from './MobileScaffoldingForm'

const yesNoOptions = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
]

const invoiceTypeOptions = [
	{ value: 'Payment Claim', label: 'Payment Claim' },
	{ value: 'Invoice', label: 'Invoice' },
]
interface IProps {
	job_id: number | undefined
	setHandover: (value: string | number) => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handover: any
	job: any
}

interface IInitialValues {
	job_start_date: string
	job_end_date: string
	billing_address: string
	credit_check: string
	work_safe: string
	sssp_added: string
	hs_officer_phone: string
	hs_officer_email: string
	site_forman_email: string
	site_forman_phone: string
	gear_shortages: string
	allowed_quote: string
	engaged_engineer: string
	staff_availability: string
	booked_shrinkwrappers: string
	credit_check_who: string
	credit_check_when: string
	swms_added: string
	worksafe_uploaded: string
	worksafe_uploaded_when: string
	hs_officer: string
	site_forman: string
	invoiceType: string
	hs_officer_client: string
	hs_officer_client_number: string
	hs_officer_client_email: string
	site_forman2: string
	site_forman_phone2: string
	site_forman_email2: string
	scaffold_type: string
	swms_id: number
	po_number: string
	estimator_name: string
	estimator_email: string
	tower_size: string
	deck_level: string
	send_email: boolean
}

export const HandoverFrom = ({
	job_id,
	setHandover,
	handover,
	job,
}: IProps) => {
	const [updateLoading, setUpdateLoading] = useState(false)
	const [isDone, setIsDone] = useState(false)
	const { update: updateHandover } = JobsServices.useUpdateHandover()
	const { update: updateJob } = JobsServices.useUpdateJob()
	const { data: swmsList } = SWMSDocumentsServices.useSWMSDocuments()
	const { sendHandoverEmail } = JobsServices.useSendHandoverEmail()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	console.log('aa', job)

	const initialValues: IInitialValues = {
		// Financials
		job_start_date: job?.start_date
			? moment(job.start_date).format('DD/MM/YYYY').toString()
			: moment().format('DD/MM/YYYY').toString(),
		job_end_date: job?.end_date
			? moment(job.end_date).format('DD/MM/YYYY').toString()
			: moment().format('DD/MM/YYYY').toString(),
		billing_address: handover?.billing_address || 'No',
		allowed_quote: handover?.allowed_quote || ' ',
		credit_check: handover?.credit_check || 'No',
		credit_check_who: handover?.credit_check_who || ' ',
		credit_check_when:
			handover?.credit_check_when !== 'Invalid date' &&
			handover?.credit_check_when !== ' ' &&
			handover?.credit_check_when
				? moment(handover?.credit_check_when, 'DD/MM/YYYY').format('DD/MM/YYYY')
				: '',
		invoiceType: handover?.invoiceType || 'Invoice',
		hs_officer: handover?.hs_officer || '',
		hs_officer_phone: handover?.hs_officer_phone || '',
		hs_officer_email: handover?.hs_officer_email || '',
		site_forman:
			handover?.site_forman || job?.clientData?.mainContactData?.name || ' ',
		site_forman_email:
			handover?.site_forman_email ||
			job?.clientData?.mainContactData?.email ||
			' ',
		site_forman_phone:
			handover?.site_forman_phone ||
			job?.clientData?.mainContactData?.phone ||
			' ',
		work_safe: handover?.work_safe || ' ',
		worksafe_uploaded: handover?.worksafe_uploaded || ' ',
		worksafe_uploaded_when: handover?.worksafe_uploaded_when
			? moment(handover?.worksafe_uploaded_when).format('DD/MM/YYYY')
			: moment().format('DD/MM/YYYY'),
		sssp_added: handover?.sssp_added || ' ',
		swms_added: handover?.swms_added || ' ',
		site_forman2: handover?.site_forman2 || ' ',
		site_forman_email2: handover?.site_forman_email2 || ' ',
		site_forman_phone2: handover?.site_forman_phone2 || ' ',
		gear_shortages: handover?.gear_shortages || ' ',
		engaged_engineer: handover?.engaged_engineer || ' ',
		staff_availability: handover?.staff_availability || ' ',
		booked_shrinkwrappers: handover?.booked_shrinkwrappers || ' ',
		hs_officer_client: handover?.hs_officer_client || ' ',
		hs_officer_client_number: handover?.hs_officer_client_number || ' ',
		hs_officer_client_email: handover?.hs_officer_client_email || ' ',
		scaffold_type: job?.scaffold_type || '',
		swms_id: handover?.swms_id || -1,
		po_number: job?.po_number || '',
		estimator_name: handover?.estimator_name || ' ',
		estimator_email: handover?.estimator_email || ' ',
		tower_size: job?.tower_size || '',
		deck_level: job?.deck_level || '',
		send_email: handover?.send_email || true,
	}
	const validationSchema = Yup.object().shape({
		swms_id: Yup.number()
			.min(0, 'You must select a SWMS Document')
			.required('You must select a SWMS Document'),
	})
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema,
		isInitialValid: initialValues.swms_id !== -1,
		initialErrors:
			initialValues.swms_id !== -1
				? {}
				: { swms_id: 'You must select a SWMS Document' },
		onSubmit: async (values, { setSubmitting }) => {
			if (!job_id) {
				console.error('Handover submitted but no job id was present')
				return
			}
			const body = {
				...initialValues,
				billing_address: values.billing_address,
				credit_check: values.credit_check,
				credit_check_who: values.credit_check_who,
				credit_check_when: values.credit_check_when || ' ',
				invoiceType: values.invoiceType,
				hs_officer: values.hs_officer,
				hs_officer_phone: values.hs_officer_phone,
				hs_officer_email: values.hs_officer_email,
				site_forman: values.site_forman,
				site_forman_email: values.site_forman_email,
				site_forman_phone: values.site_forman_phone,
				jobData: handover.jobData,
				swms_id: values.swms_id,
				estimator_name: values.estimator_name,
				estimator_email: values.estimator_email,
			}
			try {
				setUpdateLoading(true)
				const feedbackMessage =
					handover.invoiceType == ''
						? 'Document created successfully'
						: 'Handover details updated successfully'

				await updateHandover(handover.id, body, feedbackMessage)
				await updateJob(job_id, {
					start_date: values.job_start_date,
					end_date: values.job_end_date,
					scaffold_type: values.scaffold_type,
					po_number: values.po_number,
					tower_size: values.tower_size,
					deck_level: values.deck_level,
				})
				if (handover.invoiceType == '') {
					await sendHandoverEmail(job_id)
				}
				setHandover({
					...handover,
					...body,
				})
			} finally {
				setUpdateLoading(false)
				setSubmitting(false)
				formik.resetForm()
				setIsDone(true)
			}
		},
	})

	useEffect(() => {
		// Update end date so it matches time after start date of "Steel Jobs"- 6 Weeks, "System/Aliminium Jobs" 1 Week
		if (formik.values.scaffold_type === 'VOIDS') {
			return
		}
		if (formik.values.scaffold_type === 'STEEL') {
			formik.setFieldValue(
				'job_end_date',
				moment(formik.values.job_start_date, 'DD/MM/YYYY')
					.add(6, 'weeks')
					.format('DD/MM/YYYY')
			)
		} else {
			formik.setFieldValue(
				'job_end_date',
				moment(formik.values.job_start_date, 'DD/MM/YYYY')
					.add(1, 'weeks')
					.format('DD/MM/YYYY')
			)
		}
	}, [formik.values.scaffold_type])

	if (!job_id || staffLoading) {
		return <></>
	}
	return (
		<ConfirmationDialog
			size="4xl"
			icon="info"
			title="Start Date Confirmation Document"
			body=""
			triggerButton={
				<Button
					size="sm"
					variant="inverse"
					className="mt-4"
					startIcon={<DocumentIcon className="w-4 h-4" />}>
					Confirm Start Date
				</Button>
			}
			confirmButton={
				updateLoading ? (
					<ProgressSpinner
						style={{ width: '50px', height: '50px' }}
						strokeWidth="8"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				) : (
					<Button
						type="submit"
						form="handoverForm"
						variant="approve"
						size="md"
						disabled={!formik.isValid}
						onClick={() => {
							formik.handleSubmit()
						}}>
						{handover.invoiceType == '' ? (
							<>Confirm Start Date</>
						) : (
							<>Update Start Date</>
						)}
					</Button>
				)
			}>
			<div>
				{/** ****************************************
				 *
				 * FINANCIALS
				 *
				 **************************************** */}
				{/** <div>
					<h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">
						FINANCIALS
					</h2>
					<div className="border-b" />
					<div className="flex items-center">
						<Dropdown
							label="Billing Address Same as Client?"
							id="billing_address"
							value={formik.values.billing_address}
							error={formik.errors.billing_address}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
						<Dropdown
							label="Credit Check Completed?"
							id="credit_check"
							value={formik.values.credit_check}
							error={formik.errors.credit_check}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
					</div>
					{formik.values.credit_check === 'Yes' ? (
						<div className="flex items-center">
							<Input
								title="By Who?"
								id="credit_check_who"
								placeholder=""
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.credit_check_who}
								error={formik.errors.credit_check_who}
							/>
							<DateSelect
								title="When?"
								id="credit_check_when"
								value={formik.values.credit_check_when}
								error={formik.errors.credit_check_when}
								onChange={formik.setFieldValue}
							/>
						</div>
					) : (
						<></>
					)}
					<div className="flex items-center">
						<Dropdown
							label="Invoice Type"
							id="invoiceType"
							value={formik.values.invoiceType}
							error={formik.errors.invoiceType}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={invoiceTypeOptions}
						/>
					</div>
				</div> */}
				{/** ****************************************
				 *
				 * HEALTH & SAFETY
				 *
				 **************************************** */}
				{/* <div>
					<h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">
						Health & Safety
					</h2>
					<div className="border-b" />

					<div className="flex items-center">
						<Input
							title="H&S Officer"
							placeholder=""
							id="hs_officer"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.hs_officer}
							error={formik.errors.hs_officer}
						/>
						<Input
							title="H&S Officer Phone #"
							placeholder=""
							id="hs_officer_phone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.hs_officer_phone}
							error={formik.errors.hs_officer_phone}
						/>
						<Input
							title="H&S Officer Email"
							placeholder=""
							id="hs_officer_email"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.hs_officer_email}
							error={formik.errors.hs_officer_email}
						/>
					</div>
				</div> */}

				{/** ****************************************
				 *
				 * OPERATIONS
				 *
				 **************************************** */}
				<div>
					<h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4 ">
						Operations
					</h2>
					<div className="border-b" />
					<div className="flex items-center justify-between">
						<div className="w-1/2">
							<Input
								title="Client Site Contact"
								placeholder=""
								id="site_forman"
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.site_forman}
								error={formik.errors.site_forman}
							/>
						</div>
						<div className="w-1/2 flex items-center justify-between">
							<DateSelect
								title="Start Date"
								id="job_start_date"
								value={formik.values.job_start_date}
								onChange={formik.setFieldValue}
							/>
							<DateSelect
								title="Finish Date"
								id="job_end_date"
								value={formik.values.job_end_date}
								onChange={formik.setFieldValue}
							/>
						</div>
					</div>
					<div className="flex items-center">
						<Input
							title="Client Site Contact Phone"
							placeholder=""
							id="site_forman_phone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.site_forman_phone}
							error={formik.errors.site_forman_phone}
						/>
						<Input
							title="Client Site Contact Email"
							placeholder=""
							id="site_forman_email"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.site_forman_email}
							error={formik.errors.site_forman_email}
						/>
					</div>
					<div className="flex items-center">
						<Dropdown
							label="Select Estimator"
							id="estimator_name"
							value={formik.values.estimator_name}
							error={formik.errors.estimator_name}
							onChange={(id, val) => {
								const staffMember = staffData?.find(
									(staff: any) => staff.staff_name === val
								)
								formik.setFieldValue(
									'estimator_email',
									staffMember?.email || ''
								)
								formik.setFieldValue(id, val)
							}}
							onBlur={formik.setFieldTouched}
							options={OptionsForDropdown(
								staffData,
								'staff_name',
								'staff_name'
							)}
						/>
						<Input
							title="Job Estimator"
							placeholder=""
							disabled
							id="estimator_name"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.estimator_name}
							error={formik.errors.estimator_name}
						/>
						<Input
							title="Job Estimator Email"
							placeholder=""
							disabled
							id="estimator_email"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.estimator_email}
							error={formik.errors.estimator_email}
						/>
						<div className='w-96 mt-5'>
							<div className="flex align-middle">
								<div className="items-center h-5">
									<input
										id={'sendEmail'}
										aria-describedby="comments-description"
										name={'sendEmail'}
										onChange={(e) => {
											formik.setFieldValue('send_email', e.target.checked)
										}}
										checked={formik.values.send_email}
										type="checkbox"
										className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
									/>
								</div>
								<div className="ml-3 text-sm">
									<label
										htmlFor={'sendEmail'}
										className="font-medium text-gray-700">
										Send Email?
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="flex items-center z-100">
						<Input
							title="PO Number"
							placeholder=""
							id="po_number"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.po_number}
							error={formik.errors.po_number}
						/>
						<Dropdown
							label="Scaffold Type"
							id="scaffold_type"
							value={formik.values.scaffold_type}
							error={formik.errors.scaffold_type}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={[
								{ value: 'SYSTEM', label: 'System' },
								{ value: 'STEEL', label: 'Steel' },
								{ value: 'ALUMINIUM', label: 'Aluminium' },
								{ value: 'VOIDS', label: 'Void Protection' },
							]}
						/>
					</div>
					{job.job_type === 'Mobile Scaffold' && (
						<div className="flex items-center">
							<Dropdown
								id="tower_size"
								label="Tower Size"
								options={optionsTowerSize}
								value={formik.values.tower_size}
								onChange={formik.setFieldValue}
								onBlur={formik.handleBlur}
								error={formik.errors.tower_size}
							/>
							<Dropdown
								id="deck_level"
								label="Deck Level"
								options={optionsDeckLevel}
								value={formik.values.deck_level}
								onChange={formik.setFieldValue}
								onBlur={formik.handleBlur}
								error={formik.errors.deck_level}
							/>
						</div>
					)}
					<div className="flex items-center z-100">
						<Dropdown
							label="SWMS Document"
							id="swms_id"
							value={formik.values.swms_id}
							error={formik.errors.swms_id}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={OptionsForDropdownNumber(swmsList, 'id', 'description')}
						/>
					</div>
				</div>
			</div>
		</ConfirmationDialog>
	)
}
